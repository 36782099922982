import * as React from 'react';

import {Alerts} from '../../ui/Alerts/Alerts';
import {FlashMessageBox} from '../../orders/checkout/FlashMessage';
import {FlashMessage} from '../../orders/checkout/CheckoutPage/CheckoutPage';
import {LoginForm} from './LoginForm';
import {OurBenefits} from '../../account/our-benefits/OurBenefits';
import {SessionStorageService} from '../../../client/session-storage/session-storage.service';
import {useEffect} from 'react';
import {useService} from '../../react/ServiceContext';
import {PageHeader} from '../../ui/elements/PageHeader';

export interface LoginPageProps {
    changedPassword: boolean;
    csrfToken: string;
    errorMessage: string;
    flashMessages: FlashMessage[];
    isImpersonate: boolean;
    isPunchout: boolean;
    loginName: string;
    reauthenticate: boolean;
    url: string;
    warningMessage: string;
}

export const LoginPage = ({
    changedPassword,
    csrfToken,
    errorMessage,
    flashMessages,
    isImpersonate,
    isPunchout,
    loginName,
    reauthenticate,
    url,
    warningMessage,
}: LoginPageProps) => {
    const _sessionStorageService: SessionStorageService = useService('sessionStorageService');
    useEffect(() => {
        if (!(isPunchout || isImpersonate || reauthenticate)) {
            _sessionStorageService.setItem(`recordGaLogin`, `login_page`);
        }
        // Only run on page init
        // eslint-disable-next-line
    }, []);

    const getListItems = () => {
        if (isPunchout) {
            return (
                <>
                    <li>To complete your transaction or access secure information, please return to your purchasing platform</li>
                    <li>The items previously added to your order have been saved</li>
                </>
            );
        }
        if (isImpersonate) {
            return <li>To complete your transaction or access secure information, please return to the account in TERM</li>;
        }
    };
    if (isImpersonate || isPunchout) {
        return (
            <div>
                <h1>Session Ended</h1>
                <Alerts
                    variant="warning"
                    message="Your session has ended."
                ></Alerts>
                <ul>{getListItems()}</ul>
            </div>
        );
    }
    if (reauthenticate) {
        return (
            <div>
                <PageHeader
                    type="h1"
                    title="Enter Password"
                />
                <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 -tw-mx-2.5">
                    <div className="tw-w-full tw-p-4">
                        {flashMessages.map((flashMessage, index) => (
                            <FlashMessageBox
                                flashMessage={flashMessage}
                                key={index}
                            />
                        ))}
                        <p>You are about to access important business information, log in to continue.</p>
                        <LoginForm
                            csrfToken={csrfToken}
                            loginName={loginName}
                            reauthenticate={reauthenticate}
                            url={url}
                        />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div data-e2e="login-page">
            <PageHeader
                type="h1"
                title="Log In to Your Account"
            />
            {flashMessages.map((flashMessage, index) => (
                <FlashMessageBox
                    flashMessage={flashMessage}
                    key={index}
                />
            ))}
            <Alerts
                message={errorMessage}
                variant="danger"
            />
            <Alerts
                message={warningMessage}
                variant="warning"
            />
            {changedPassword && (
                <Alerts
                    message="Your password has been set"
                    variant="success"
                />
            )}
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-12 tw-gap-6 tw-py-3">
                <div className="tw-w-full lg:tw-col-span-5">
                    <LoginForm
                        csrfToken={csrfToken}
                        loginName={loginName}
                        reauthenticate={reauthenticate}
                        url={url}
                    />
                </div>
                <div className="lg:tw-col-span-2 tw-hidden lg:tw-block">&nbsp;</div>
                <div className="tw-w-full lg:tw-col-span-5">
                    <OurBenefits creativeSource="Login Page" />
                </div>
            </div>
        </div>
    );
};
